<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label class="required">Tên</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Tên"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên"></el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label class="required">Link</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Link"
                v-model="form.slug"
                :class="errors.has('slug')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="slug"
                data-vv-as="Link"></el-input>
              <span class="text-danger" v-if="errors.has('slug')">{{ errors.first('slug') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label>Mô tả</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <my-editor
                v-model="form.description"
                apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
              </my-editor>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label>Website</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Website"
                v-model="form.link">
              </el-input>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-sm-12">
              <label>Bảng</label>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.group_id" placeholder="Chọn bảng" clearable class="full-width">
                <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <label>Nhóm cha</label>
            </div>
            <div class="col-sm-12">
              <el-select v-model="form.parent_id" placeholder="Chọn nhóm cha" clearable class="full-width">
                <el-option key="0" :value="-1" label="Chọn nhóm cha"></el-option>
                <el-option
                  v-for="item in clubs"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <label>Hình ảnh</label>
            </div>
            <div class="col-sm-12">
              <my-image v-model="form.image">
              </my-image>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <label>Hiển thị</label>
            </div>
            <div class="col-sm-12">
              <el-switch
                v-model="form.status"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Input, Button, Switch, Message, Select, Option } from 'element-ui';
import { mapState } from 'vuex'
import { Toast } from 'vuex-toast'
import MyEditor from 'src/components/UIComponents/Editor'
import MyImage from 'src/components/UIComponents/Image'
import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    ElSelect: Select,
    ElOption: Option,
    MyEditor,
    MyImage,
    Toast,
    Message
  },

  data() {
    return {
      form: {}
    }
  },

  computed: {
    ...mapState({
      originalForm: state => state.clubDetail,
      clubs: state => state.clubs,
      groups: state => state.groups
    })
  },

  mounted() {
    this.$store.dispatch('fetchClubs');
    this.$store.dispatch('fetchGroups');
    this.$store.dispatch('setPageTitle', 'Cập nhật CLB');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

    let id = this.$route.params.id;
    this.$store.dispatch('fetchClubDetail', id);
  },

  methods: {
    cancel() {
      this.$router.push({name: 'AllClubs'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(function (result) {
        if (result) {
          self.$store.dispatch('updateClub', self.form).then(res => {
            if (res.success) {
              Message({
                message: 'Cập nhật thành công',
                type: 'success'
              });
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        }
      })
    },
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    toast(text, type) {
      this.addToast({
        text,
        type: type,
        dismissAfter: 2000
      })
    }
  },
  watch: {
    originalForm(newVal) {
      this.form = Object.assign({}, newVal);
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.clubDetail = [];
  }
}
</script>
